<template>
<div>  
  <table class="header">
    <tr>
      <td class="logo">        
        <img class="logo-img" v-if="quoteDetails.logoImgUrl !== ''" :src="quoteDetails.logoImgUrl" />
      </td>
      <td>
        
  <table class="meta-data">
    <tbody>
      <tr>
        <td>
          <span class="label-bold" v-translation="{ code: 'label_quotes_nr', type: 'label'}"></span>
          <span>{{ quoteDetails.quoteNr }}</span>
        </td>
      </tr>
      <tr>        
        <td>
          <span class="label-bold" v-translation="{ code: 'label_quotes_date', type: 'label'}"></span>
          <span>{{ quoteDetails.quoteDate }}</span>
        </td>
      </tr>
      <tr>        
        <td>
          <span class="label-bold" v-translation="{ code: 'label_quotes_exp_date', type: 'label'}"></span>
          <span>{{ quoteDetails.expirationQuoteDate }}</span>
        </td>
      </tr>
    </tbody>
  </table>
      </td>
    </tr>
  </table>

  <table class="address-info">
    <tbody>
      <tr>
        <td>
          <p class="label-bold" v-translation="{ code: 'label_quote_from', type: 'label'}"></p>
          <p>{{ deliveryAddress.name }}</p>
          <p>{{ deliveryAddress.address }}</p>
          <p>{{ deliveryAddress.zipCode }} {{ deliveryAddress.city }}</p>
          <p>{{ deliveryAddress.country }}</p>
          <p>{{ deliveryAddress.phone }}</p>
          <p>{{ deliveryAddress.email }}</p>
        </td>
        <td>
          <p class="label-bold" v-translation="{ code: 'label_quote_to', type: 'label'}"></p>
          <p>{{ invoiceAddress.name }}</p>
          <p>{{ invoiceAddress.address }}</p>
          <p>{{ invoiceAddress.zipCode }} {{ invoiceAddress.city }}</p>
          <p>{{ invoiceAddress.country }}</p>
          <p>{{ invoiceAddress.phone }}</p>
          <p>{{ invoiceAddress.email }}</p>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="order-lines">
    <thead>
      <th class="prod-img"></th>
      <th class="desc" v-translation="{ code: 'label_col_desc', type: 'label'}"></th>
      <th class="price" v-translation="{ code: 'label_col_price', type: 'label'}"></th>
      <th class="qty" v-translation="{ code: 'label_col_qty', type: 'label'}"></th>
      <th class="amt" v-translation="{ code: 'label_col_amt', type: 'label'}"></th>
    </thead>
    <tbody>
      <tr v-for="line in quoteLines" :key="line.recId">
        <td class="prod-img">
          <img class="img-responsive" :src="line.imageUrl" width="40px" height="40px">
        </td>
        <td class="desc">
          <span>{{ line.productDescription }}</span>
          <template v-if="!line.customProduct">
            <span class="text-small">{{ line.productId }}</span>
          </template>
        </td>
        <td class="price">{{ formatPrice(line.productPrice) }}</td>
        <td class="qty">{{ line.quantity }}</td>
        <td class="amt">{{ formatPrice(line.rowAmount) }}</td>
      </tr>
    </tbody>
  </table>

  <table class="table totals">
    <tbody>
      <tr>
        <td>
          <span v-translation="{ code: 'label_subtotal_excl', type: 'label' }"></span>
          </td>
        <td>
          <span class="label-bold">{{ totalExcl }}</span>
        </td>
      </tr>
      <template v-if="applyingDiscount">
        <tr>
          <td>
            <span v-translation="{ code: 'label_disc_excl', type: 'label' }"></span>
            </td>
          <td>
            <span>- </span> 
            <span class="label-bold">{{ discountTotal }}</span>
          </td>
        </tr>
      </template>
      <tr>
        <td>
          <span v-translation="{ code: 'label_total_vat', type: 'label' }"></span>
          </td>
        <td>
          <span class="label-bold">{{ vatTotal }}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span v-translation="{ code: 'label_total_incl_vat', type: 'label' }"></span>
          </td>
        <td>
          <span class="label-bold">{{ totalIncl }}</span>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="signature">
    <tr>
      <td><span class="label-bold" v-translation="{ code: 'label_cust_signature', type: 'label' }"></span></td>
    </tr>
    <tr class="sig-line">
      <td></td>
    </tr>
  </table>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { getProductInformation } from 'src/services/product-service';
import { formatPrice } from 'src/utilities/helpers';
export default {
  data() {
    return {
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      quoteLines: [],
      quoteDetails: {},
      deliveryAddress: {},
      invoiceAddress: {},
      totalExcl: 0,
      discountTotal: 0,
      discountIncludingVat: 0,
      vatTotal: 0,
      totalIncl: 0,
      applyingDiscount: false
    }
  },
  computed: {
    ...mapGetters(['customerUserId', 'bespokeGlobalWs', 'language']),
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    setTotals({ TotalExcl, VatTotal, GrandTotal, Disc, DiscIncludingVat }) {
      this.totalExcl = formatPrice(TotalExcl);
      this.discountTotal = formatPrice(Disc);
      this.discountIncludingVat = formatPrice(DiscIncludingVat);
      this.vatTotal = formatPrice(VatTotal);
      this.totalIncl = formatPrice(GrandTotal);

      this.applyingDiscount = Disc > 0;
    }
  },
  created() {

    var quoteId = new URLSearchParams(window.location.search).get('quoteid');

    if (quoteId !== null) {

      axios.post(this.endpoint + "GetQuotations", { debtorId: this.customerUserId, recId: quoteId })
        .then(res => {
          var details = res.data.d[0];
          axios.post(this.endpoint + "GetQuoteTotals", { recId: quoteId })
            .then(totalsRes => {
                this.setTotals(totalsRes.data.d);
            

            var dateString = details.QuoteDate;
            let parts = dateString.split('-');
            let formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

            var expDateString = details.QuoteExpirationDate;
            let expParts = expDateString.split('-');
            let formattedExpDateString = `${expParts[1]}/${expParts[0]}/${expParts[2]}`

            this.quoteDetails = {
              debtorId: "",
              discountAmount: details.Disc,
              discountType: details.DiscType,
              quoteDate: new Date(formattedDateString).format("dd-MM-yyyy"),
              expirationQuoteDate: new Date(formattedExpDateString).format("dd-MM-yyyy"),
              quoteNr: details.RecId,
              grandTotal: details.GrandTotal,
              totalExcl: details.TotalExcl,
              vatTotal: details.VatTotal,
              logoImgUrl: details.LogoImgUrl            
            };

            var lines = [];
            details.Lines.forEach(x => {
              lines.push({
                productId: x.ProdCode,
                productDescription: x.ProdDesc,
                quantity: x.Qty,
                rowAmount: x.LineTotal,
                productPrice: x.ProductPrice,
                recId: x.RecId,
                rowOrder: x.RowOrd,
                customProduct: x.ProdCode === this.bespokeGlobalWs.customQuoteProduct ? true : false
              });
            });

            this.deliveryAddress = {
              name: details.DelAddress.Name,
              address: details.DelAddress.Address,
              countryCode: details.DelAddress.CntCode,
              email: details.DelAddress.Email,
              city: details.DelAddress.City,
              phone: details.DelAddress.Phone,
              zipCode: details.DelAddress.ZipCode
            };

            this.invoiceAddress = {
              name: details.InvAddress.Name,
              address: details.InvAddress.Address,
              countryCode: details.InvAddress.CntCode,
              email: details.InvAddress.Email,
              city: details.InvAddress.City,
              phone: details.InvAddress.Phone,
              zipCode: details.InvAddress.ZipCode
            };

            var prodCodes = lines.filter(y => !y.customProduct).map(x => x.productId);
            if (prodCodes.length > 0) {
              getProductInformation({ prodCodes: prodCodes, getSeoInformation: false, getStock: false, getPrice: false })
              .then(res => {
                res.products.forEach(productInfo => {                
                  lines.forEach(x => {
                    if (x.productId === productInfo.id) {                      
                      x.imageUrl = productInfo.imageUrl ? productInfo.imageUrl : '/documents/productimages/not-available-small.jpg';
                    }
                  });
                });

                this.quoteLines = lines;    
                setTimeout(() => { 
                  window.print();
                }, 500);
              });          
            } else {
              this.quoteLines = lines;
              setTimeout(() => { 
                  window.print();
                }, 500);
            }
         
          });
                    
        });   
    }
    
  }
}
</script>

<style>

</style>