import Vue from 'vue';
import ElasticProductList from 'src/components/webshop/elastic/elastic-product-list/ElasticProductList.vue';
import ElasticProductDetails from 'src/components/webshop/elastic/elastic-product-details/ElasticProductDetails.vue';
import ProductBlock from 'src/components/webshop/product-block/ProductBlock.vue';
import ElasticProductFilters from 'src/components/webshop/elastic/elastic-product-filters/ElasticProductFilters.vue';
import ShoppingCartControl from 'src/components/webshop/shopping-cart/ShoppingCartControl.vue';
import store from 'src/store/store';

// BESPOKE
import QuotationsList from './../bespoke/quotations/QuotationsList.vue';
import QuotationDetails from './../bespoke/quotations/QuotationDetails.vue';
import SelectQuote from './../bespoke/quotations/SelectQuote.vue';
import PrintQuotation from './../bespoke/quotations/PrintQuotation.vue';
// END BESPOKE

// Directives
import TranslationDirective from 'src/directives/TranslationDirective';
import FormatPriceDirective from 'src/directives/FormatPriceDirective';
import OutsideClickDirective from 'src/directives/OutsideClickDirective';

// Setup global directives
Vue.directive('translation', TranslationDirective);
Vue.directive('format-price', FormatPriceDirective);
Vue.directive('outside-click', OutsideClickDirective);

// Initializes the store with global settings from uc_vue_data_transfer
window.vuexStore = new Vue({
  store: store,
  created () {
    this.$store.dispatch('initBrowserDetection');
    this.$store.dispatch('setGlobalCmsSettings', window.vue.globalData.cmsSettings);
    this.$store.dispatch('setGlobalWsSettings', window.vue.globalData.webshopSettings);
    this.$store.dispatch('setGlobalUserInformation', window.vue.globalData.userInformation);
    this.$store.dispatch('setGlobalEndpoints', window.vue.globalData.endpoints);
  }
});

window.loadElasticSearch = function () {
  // Initializes elastic search module
  const elasticSearch = window.vue.modules.elasticSearch;
  if (elasticSearch) {
    new Vue({
      store: store,
      created () {
        this.$store.commit('elastic/initConfig', elasticSearch.config.config);
        this.$store.commit('elastic/initSearchConfig', elasticSearch.config.searchConfig);
      },
      render: h => h(ElasticProductList)
    }).$mount('#elastic-search');
  }
}

window.loadElasticProductDetails = function () {
  // Initializes elastic product details module
  const productDetails = window.vue.modules.productDetails;
  if (productDetails) {
    new Vue({
      store: store,
      created () {
        this.$store.commit('productDetails/setProductCode', productDetails.config.productCode);
        this.$store.commit('productDetails/setGroupFields', productDetails.config.groupFields);
        this.$store.commit('productDetails/setSearchConfig', productDetails.config.searchConfig);
      },
      render: h => h(ElasticProductDetails)
    }).$mount('#elastic-detail-page');
  }
}

// Initializes elastic aggregations module
const elasticAggrFilters = window.vue.modules.elasticAggrFilters;
if (elasticAggrFilters) {
  new Vue({
    store: store,
    render: l => l(ElasticProductFilters)
  }).$mount('#elastic-aggr-filters');
};

if (window.vue.modules.ShoppingCartControl) {
  new Vue({
    store: store,
    render: h => h(ShoppingCartControl)
  }).$mount('#shopping-cart-control');
};

window.vue.productBlocks.forEach(config => {
  new Vue({
    store: store,
    data: {
      config: config
    },
    render: h => h(ProductBlock)
  }).$mount('#block-' + config.Target);
});

// BESPOKE
// Initializes elastic aggregations module
const quotations = window.vue.modules.quotations;
if (quotations) {
  new Vue({
    store: store,
    render: l => l(QuotationsList)
  }).$mount('#quotations');
};

window.loadVueJsQuotationTool = function() {
  const quotationsStore = window.vue.modules.quotationsStore;
  if (quotationsStore) {
    new Vue({
      store: store,
      created() {
        this.$store.dispatch('quotations/init', {});
      },
      render: l => l(SelectQuote)
    }).$mount('#select-quote');;
  };
}

const quotationDetails = window.vue.modules.quotationDetails;
if (quotationDetails) {
  new Vue({
    store: store,
    created() {
      this.$store.commit('orderEntry/initSearchConfig', quotationDetails.config.searchConfig);
    },
    render: l => l(QuotationDetails)
  }).$mount('#quotation-details');
};

const printQuotationDetails = window.vue.modules.printQuotationDetails;
if (printQuotationDetails) {
  new Vue({
    store: store,
    render: l => l(PrintQuotation)
  }).$mount('#print-quotation-details');
};
// END BESPOKE

Vue.config.productionTip = false
