<template>
  <div class="col-md-12 select-disc">
    <div class="row">
      <div class="col-md-offset-6 col-md-4">
        <select class="form-control test" v-model="quotation.discountType" @change="updateDiscountType($event)">
          <option value="0">{{ discountAmountLabel }}</option>
          <option value="1">{{ discountPercentageLabel }}</option>
        </select>
      </div>
      
      <div class="col-md-2" :class="{ discPrice : quotation.discountType == 0, discPerc : quotation.discountType == 1 }" >
        <utlz-decimal-field :maxValue="maxValue" :initialValue="quotation.discountAmount" @valueUpdatedEvent="updateDiscountAmount($event)"></utlz-decimal-field>
      </div>
    </div>
  </div>
</template>

<script>
import DecimalField from './../../bespoke/quotations/DecimalField.vue';
export default {
  props: {
    quotation: { type: Object, required: true}
  },
  data() {
    return {
      maxValue: 0,
      discountAmountLabel: window.vue.translations.label.label_quote_dd_disc_amt,
      discountPercentageLabel: window.vue.translations.label.label_quote_dd_disc_perc
    };
  },
  components: {
    'utlz-decimal-field': DecimalField
  },
  methods: {
    updateDiscountType(ev) {
      var discountType = parseInt(ev.target.value);
      var payload = {
        amt: 0,
        type: discountType
      }
      this.setDiscountMaxValue(discountType);
      this.$emit('updateDiscountEvent', payload);
    },
    updateDiscountAmount(amount) {
      var payload = {
        amt: amount,
        type: this.quotation.discountType
      }
      this.$emit('updateDiscountEvent', payload);
    },
    setDiscountMaxValue(discountType) {
      if (discountType === 0) {
        this.maxValue = 0;
      } else if (discountType === 1) {
        this.maxValue = 100;
      }
    }
  },
  created() {
    this.setDiscountMaxValue(this.quotation.discountType);
  }
}
</script>

<style>

</style>