<template>
  <div class="cmp-search-product rows">
    
    <h2 v-translation="{ type:'title', code: 'title_add_prodtoloc' }"></h2>
    
    <utlz-tabs :tabs="tabs">

      <template v-slot:existingproduct>

        <div class="existing-product">
           <div class="form-group-custom">
            <div class="form-group">
                <label for="city" v-translation="{ code: 'label_search_by_prod', type: 'label'}"></label>                    
                <utlz-searchbar id="searchbar" :placeholder="placeholder" v-model="searchText" />
            </div>
           </div>

          <transition name="fade">
            <div class="entry-product" v-if="selectedProduct.prices !== undefined">
              <div class="prod-img">
                <img :src="selectedProduct.imageUrl ? selectedProduct.imageUrl : '/documents/productimages/not-available-small.jpg'" />
              </div>
              <div class="prod-desc">
                <p>{{ selectedProduct.shortDescription }}</p>          
                <p class="prod-code">{{ selectedProduct.id }}</p>
              </div>
              <div class="prod-price" v-if="selectedProduct.prices.length">
                <utlz-product-price            
                  :prices="selectedProduct.prices[0]"
                  ></utlz-product-price>
              </div>
              <div class="quantity">
                <utlz-quantity-buttons
                    :initialQuantity="1"
                    :allowZeroQuantity="false"
                    :quantityFactor="1"
                    @quantityChanged="quantityChanged($event)">
                </utlz-quantity-buttons>
              </div>
              <div class="add-product-btn">
                <a @click="addExistingProductToQuotation(selectedProduct.id)" v-translation="{ code: 'button_add_product', type: 'button'}" class="btn-u btn-u-sea-shop btn-block"></a>
              </div>
            </div>
          </transition>

        </div>
      </template>

      <template v-slot:newproduct>
        <div class="new-product">
          <div class="form-group-custom">
             <div class="form-group input-desc">
                <label for="desc" v-translation="{ code: 'label_add_cust_desc', type: 'label'}"></label>
                <input type="text" class="form-control" id="desc" :placeholder="placeholderCustProd" v-model="customProductDesc">
              </div>
              <div class="form-group input-price">
                <label for="desc" v-translation="{ code: 'label_add_cust_price', type: 'label'}"></label>
                <utlz-decimal-field :initialValue="customProductPrice" @valueUpdatedEvent="updatePrice($event)"></utlz-decimal-field>
              </div>
          </div>
          <div class="add-product-btn">
            <a @click="addCustomProductToQuotation()" v-translation="{ code: 'button_add_product', type: 'button'}" class="btn-u btn-u-sea-shop btn-block"></a>
          </div>
        </div>
      </template>

    </utlz-tabs>


    
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import Searchbar from './../../src/components/webshop/search-bar/Searchbar.vue';
  import ProductListItem from './../../src/components/webshop/products/product-list-item/ProductListItem.vue';
  import ProductPrice from './../../src/components/webshop/product-price/ProductPrice.vue'
  import QuantityButtons from './../../src/components/webshop/order-product/blocks/QuantityButtons.vue';
  import DecimalField from './../../bespoke/quotations/DecimalField.vue';
  import Tabs from 'src/components/ui/tabs/Tabs.vue';
  export default {
    data() {
      return {
        customProductPrice: 0,
        customProductDesc: "",
        placeholder: window.vue.translations.label.label_ph_search_prod,
        searchText: '',
        setQuantity: 1,
        tabs: [
          { id: 'existingproduct', description: window.vue.translations.label.label_search_prod, active: true, visible: true },
          { id: 'newproduct', description: window.vue.translations.label.label_add_custom_prod, active: false, visible: true }
        ],
        placeholderCustProd: window.vue.translations.label.label_phcust_prod
      }
    },
    methods: {
      ...mapActions('orderEntry', ['clearSelectedProduct']),
      updatePrice(customProductPrice) {
        this.customProductPrice = customProductPrice;
      },
      addExistingProductToQuotation() {
        const quoteOrderLine = {
          productPrice: this.selectedProduct.prices[0].rawPrice,
          productDescription: this.selectedProduct.shortDescription,
          productId: this.selectedProduct.id,
          imageUrl: this.selectedProduct.imageUrl ? this.selectedProduct.imageUrl : '/documents/productimages/not-available-small.jpg',
          quantity: 1,
          rowAmount: this.selectedProduct.prices[0].rawPrice,
          customProduct: false
        };

        this.searchText = '';
        this.setQuantity = 1;
        this.clearSelectedProduct();
        this.$emit('addExistingProductToQuotation', quoteOrderLine);
      },
      addCustomProductToQuotation() {
        const quoteOrderLine = {
          productPrice: this.customProductPrice,
          productDescription: this.customProductDesc,
          productId: this.bespokeGlobalWs.customQuoteProduct,
          quantity: 1,
          rowAmount: this.customProductPrice,
          customProduct: true
        };
        this.$emit('addExistingProductToQuotation', quoteOrderLine);
        this.searchText = '';
        this.setQuantity = 1;
        this.clearSelectedProduct();
      },
      quantityChanged(ev) {
        this.setQuantity = ev.quantity;
      }
    },
    components: {
      'utlz-searchbar': Searchbar,
      'utlz-product-list-item': ProductListItem,
      'utlz-product-price': ProductPrice,
      'utlz-quantity-buttons': QuantityButtons,
      'utlz-tabs': Tabs,
      'utlz-decimal-field': DecimalField
    },
    computed: {
      ...mapState('orderEntry', ['selectedProduct']),
      ...mapGetters(['bespokeGlobalWs'])
    }
  }
</script>

<style>
</style>
